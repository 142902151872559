
import Vue, { PropType } from "vue";

import { Hint } from "@prestonly/preston-common";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import HintCard from "@/components/HintCard.vue";
import { DialogI } from "@/types/dialog";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "DisplayHintsListDialog",
  components: {
    BaseDialog,
    HintCard,
    Btn,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  computed: {
    lessonId(): string {
      return this.dialog.config.payload?.lessonId || "";
    },
    hints(): Hint[] {
      return this.$store.getters["hint/getLessonHints"](this.lessonId);
    },
  },

  async created() {
    await this.$store.dispatch("hint/getLessonHints", { lessonId: this.lessonId });
  },
});
